import React from 'react';

interface CheckListProps {
  className?: '';
  data?: string[];
  size?: 'small' | 'medium' | 'large';
}

const getTextSize = size => {
  switch (size) {
    case 'small':
      return 'text-sm';
    case 'medium':
      return 'text-base';
    default:
      return 'text-lg';
  }
};

export const CheckList = ({
  className,
  data = [],
  size,
  ...props
}: CheckListProps) => (
  <ul className={[`${className}`].join('')} {...props}>
    {data.map(item => (
      <li
        key={item}
        className={[
          getTextSize(size),
          'flex text-white font-primary py-2.5 text-left'
        ].join(' ')}
      >
        <img className={['pr-2'].join('')} src="icons/checked.svg" alt="icon" />
        {item}
      </li>
    ))}
  </ul>
);
