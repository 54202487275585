import React from 'react';
import { TitlesPropsType } from '../../types/Page';

// Components
import LazyLoadImage from 'src/components/LazyLoadImage';

const Titles = ({
  className = '',
  title,
  subTitle = '',
  linkIcon = '',
  salutionTitle = '',
  dogeTitle = '',
  kingTitle = '',
  ...props
}: TitlesPropsType) => {
  return (
    <h5 className={`${className}`} {...props}>
      <p dangerouslySetInnerHTML={{ __html: title }}></p>
      {linkIcon && (
        <div className="w-14 sm:w-24 xl:w-36 px-2 sm:px-4 xl:px-5">
          <LazyLoadImage
            alt="icon communicate"
            src={linkIcon}
            width={100}
            height={100}
          />
        </div>
      )}
      {salutionTitle && <br />}
      {salutionTitle}
      {dogeTitle && (
        <p className="px-1 inline">
          <span className="text-highlight">{dogeTitle}</span>
          {kingTitle && <span className="text-white">{kingTitle}</span>}
        </p>
      )}
      {subTitle}
    </h5>
  );
};

export default Titles;
