import React, { useState, useEffect } from 'react';

// Utils
import { formatTime } from 'src/utils/helper';

// Constant
import { REFERRAL_STYLES } from 'public/constants/styles-constants';

interface CountDownTimerProps {
  onCountEnd?: () => void;
}

export const CountDownTimer = ({ onCountEnd }: CountDownTimerProps) => {
  // Check to set time count
  const [timeCount, setTimeCount] = useState(60);
  let timeLeft = 60;
  let timerId = null;

  const countTimeDown = () => {
    if (timeLeft === -1) {
      clearTimeout(timerId);
      onCountEnd();
    } else {
      setTimeCount(timeLeft);
    }
    timeLeft--;
  };

  useEffect(() => {
    timerId = setInterval(countTimeDown, 1000);
  }, []);

  return (
    <>
      {timeCount < 60 && timeCount > 0 && (
        <span className={`${REFERRAL_STYLES.timeCountDown} text-sm mt-6 xs:mx-7 xs:my-9`}>
          New code sent! You can request a new code in{' '}
          <span>{formatTime(timeCount)}</span> s
        </span>
      )}
    </>
  );
};
