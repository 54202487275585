import React, { useEffect } from 'react';

// Components
import { Button } from '../components/Button';

// Sections
import Titles from '../sections/Titles';
import SelectCard from '../sections/SelectCard';
import VerificationCodeModal from '../sections/VerificationCode';

//Constant
import { HOME_STYLES } from 'public/constants/styles-constants';

// Types
import { ClaimCardInfoPropsType } from '../types/Page';

// Hocs/Utils
import {
  useSelectCardModal,
  useVerificationCodeModal
} from 'src/hocs/useModal';

export const ClaimCardInfo = ({
  titleHeader = '',
  labelButton = '',
  className = '',
  titleInfo = {
    title: '',
    subTitle: '',
    dogeTitle: ''
  },
  content = '',
  onClickTopVideo = () => {}
}: ClaimCardInfoPropsType) => {
  const hasTitle = !!titleHeader;

  const { title = '', subTitle = '', dogeTitle = '' } = titleInfo;

  const {
    toggleModal: toggleSelectCardModal,
    onClose: onCloseSelectCardModal,
    onSubmit: onSubmitSelectCardModal,
    apiError: apiErrorSignIn,
    processing: processingSignIn,
    setApiError: resetErrorSignIn
  } = useSelectCardModal();

  const {
    toggleModal: toggleVerificationCodeModal,
    onOpen: onOpenVerificationCodeModal,
    onClose: onCloseVerificationModal,
    onSubmit: onSubmitVerification,
    apiError: apiErrorVerification,
    processing: processingVerification,
    setApiError: resetErrorVerification
  } = useVerificationCodeModal();

  useEffect(() => {
    resetErrorSignIn('');
    resetErrorVerification('');
  }, []);

  return (
    <div className="flex w-full justify-center md:justify-start">
      {hasTitle && (
        <div
          className={`flex flex-col font-quaternary-regular mt-0 mb-10 md:my-2 items-center md:items-start ${className}`}
        >
          <h4
            className={`${HOME_STYLES.itemTitle} font-bold md:text-3xl text-xl justify-center md:justify-start`}
          >
            {titleHeader}
          </h4>
          <Titles
            className={`sm:text-xl mt-8 mb-12 text-base ${HOME_STYLES.itemSubtitle} md:text-2xl`}
            title={titleInfo.title}
            subTitle={titleInfo.subTitle}
            dogeTitle={titleInfo.dogeTitle}
          />
          {content && <div dangerouslySetInnerHTML={{ __html: content }}></div>}
          {labelButton && (
            <Button
              primary
              size={HOME_STYLES.sizeButton}
              label={labelButton}
              onClick={onClickTopVideo}
            />
          )}
          {toggleSelectCardModal && (
            <SelectCard
              apiError={apiErrorSignIn}
              loading={processingSignIn}
              onClose={onCloseSelectCardModal}
              onSubmit={data =>
                onSubmitSelectCardModal(data, onOpenVerificationCodeModal)
              }
            />
          )}
          {toggleVerificationCodeModal && (
            <VerificationCodeModal
              apiError={apiErrorVerification}
              loading={processingVerification}
              onClose={onCloseVerificationModal}
              onSubmit={onSubmitVerification}
            />
          )}
        </div>
      )}
    </div>
  );
};
