import React from 'react';
import Link from 'next/link';
import { ROUTES } from '../../constants/routes';

interface SubContentProps {
  andText: string;
  privateText: string;
  className?: string;
}

export const SubText = ({
  andText,
  privateText,
  className,
  ...props
}: SubContentProps) => (
  <p className={[`text-sm inline ${className}`].join('')} {...props}>
    <span className={['text-note'].join('')}>{andText}</span>
    <Link href={ROUTES.LEGAL_PRIVACY_PAGE_LINK}>
      <span
        className={['text-yellow text-highlight mx-0.5 cursor-pointer'].join(
          ''
        )}
      >
        {privateText}
      </span>
    </Link>
  </p>
);
