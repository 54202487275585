import React from 'react';
import Link from 'next/link';

// Types
import { FeaturesInfoPropsType } from '../../types/Page';

// Components
import LazyLoadImage from 'src/components/LazyLoadImage';

// Constant
import { FEATURES_STYLES } from 'public/constants/styles-constants';

const FeaturesInfo = ({
  iconPath,
  iconName = '',
  info = '',
  iconWidth = 0,
  iconHeight = 0,
  iconLinkTo = '',
  className = '',
  ...props
}: FeaturesInfoPropsType) => {
  return (
    <div
      className={`${FEATURES_STYLES.contentLayout} flex flex-col items-center mx-auto max-w-sm`}
      {...props}
    >
      <div
        className={`${FEATURES_STYLES.iconLayout} flex items-center justify-center order-2`}
      >
        {!!iconLinkTo ? (
          <Link href={iconLinkTo}>
            <a target="_blank">
              <LazyLoadImage
                alt={iconName}
                src={iconPath}
                width={iconWidth}
                height={iconHeight}
              />
            </a>
          </Link>
        ) : (
          <LazyLoadImage
            alt={iconName}
            src={iconPath}
            width={iconWidth}
            height={iconHeight}
          />
        )}
      </div>
      <p
        className={`sm:text-lg text-base text-center lg:px-20 sm:px-10 px-4 leading-md max-w-72 order-2 ${className}`}
        dangerouslySetInnerHTML={{ __html: info }}
      ></p>
    </div>
  );
};

export default FeaturesInfo;
