import React, { useState } from 'react';
import Link from 'next/link';

// Components
import { Button } from '../Button';
import { InputText } from '../InputText';
import { ListTitle } from '../Title/index';
import { Text } from '../Content/index';
import { SubText } from '../SubContent/index';

// Hocs/Utils
import { getStorage, STORAGE_KEYS } from '../../utils/storage';
import { formatPhone, validation } from '../../utils/helper';
import useApp from '../../hocs/useApp';

// Constant
import { ROUTES } from '../../constants/routes';
import { SITE_DATA } from 'public/constants/common';
import { CARDS, CONNECTION } from '../../constants/common';

interface ReserveCardProps {
  primary?: string;
  size?: string;
  label?: string;
  apiError?: string;
  primaryText?: string;
  secondaryText?: string;
  andText?: string;
  privateText?: string;
  className?: string;
  onSubmit: (e: any) => void;
  loading?: boolean;
}

const ReserveCard = ({
  className = '',
  loading = false,
  apiError = '',
  onSubmit = (e: any) => {},
  ...props
}: ReserveCardProps) => {
  const {
    userName,
    account,
    selectedCard,
    onSaveData,
    onSaveMultiData
  } = useApp();

  const [error, setError] = useState({
    // name: '',
    account: ''
  });

  const isPDogeCard = SITE_DATA.dogeCard;

  const validateAccount = value => {
    const key =
      !value || (!!value && value.indexOf('@')) > -1 ? 'Email' : 'Phone';
    const errorMsg = validation({ value, key });

    return {
      key,
      errorMsg
    };
  };

  const onSubmitReserveCard = () => {
    const newUserName = getStorage(STORAGE_KEYS.USER_NAME);
    const newAccount = getStorage(STORAGE_KEYS.ACCOUNT);
    const newConnection = getStorage(STORAGE_KEYS.CONNECTION);
    if (!error.account && !!newUserName && !!newAccount) {
      onSubmit({
        // name: newUserName,
        account: newAccount,
        connection: newConnection
        // cardType: selectedCard
      });
    } else {
      setError({
        account: validateAccount(newAccount).errorMsg
        // name: validation({ value: newUserName, key: 'Name' })
      });
    }
  };

  return (
    <div className={[`${className}`, 'text-left'].join(' ')} {...props}>
      <ListTitle
        primary={`${isPDogeCard ? 'Sign Up' : 'Join Waitlist'}`}
        size="tiny"
        className="uppercase md:text-3xl text-white"
      />
      <div>
        <p className="text-white font-primary text-lg mb-5 md:block hidden">
          Color: {selectedCard === CARDS[0].value ? 'Black' : 'Gold'}
        </p>
      </div>
      <InputText
        // error={error.name}
        defaultValue={userName}
        onBlur={(event: any) => {
          const value = event.target.value;
          setError({
            ...error
            // name: validation({ value, key: 'Name' })
          });
          onSaveData(value, STORAGE_KEYS.USER_NAME);
        }}
        placeholder="Name"
        size="fullWidth"
        className="my-2 mb-2"
        autoFocus={true}
      />
      <InputText
        error={error.account}
        defaultValue={account}
        onBlur={(event: any) => {
          const value = event.target.value;
          const { key, errorMsg } = validateAccount(value);
          setError({
            ...error,
            account: errorMsg
          });
          if (key === 'Phone') {
            event.target.value = formatPhone(value);
          }
          onSaveMultiData(
            [
              event.target.value,
              event.target.value,
              key === 'Phone' ? CONNECTION.SMS : CONNECTION.EMAIL
            ],
            [key.toLowerCase(), STORAGE_KEYS.ACCOUNT, STORAGE_KEYS.CONNECTION]
          );
        }}
        placeholder="Email or phone"
        size="fullWidth"
        className="my-2 mb-2"
      />
      {apiError && (
        <span className="block text-center font-medium tracking-wide text-red-500 text-xs my-5">
          {apiError}
        </span>
      )}
      <Button
        label={`${isPDogeCard ? 'Sign Up' : 'Join Waitlist'}`}
        size="fullWidth"
        onClick={onSubmitReserveCard}
        disabled={loading}
        className={['mt-4 xs:mt-3'].join('')}
      />
      <div className={['inline-block flex-wrap mt-4 xs:mt-0'].join('')}>
        <Text
          className="mr-1"
          primaryText={`By tapping ${
            isPDogeCard ? '‘Sign Up’' : 'Join Waitlist'
          }, you agree to our`}
          secondaryText="Terms, "
          primaryClassName="text-note"
          size="small"
        />
        <Link href={ROUTES.LEGAL_ELECTRONIC_PAGE_LINK}>
          <span
            className="text-yellow mr-1 cursor-pointer"
            dangerouslySetInnerHTML={{ __html: 'E-sign Consent' }}
          ></span>
        </Link>
        <SubText andText="and" privateText="Privacy Policy." />
      </div>
    </div>
  );
};

export default ReserveCard;
