import React, { useEffect } from 'react';

// Sections
import SliderCard from './SliderCard';
import Modal from './Modal';

// Components
import { CheckList } from '../components/CheckList';
import ReserveCard from '../components/ReserveCard';

// Constants
import { CARD_BENEFITS } from '../constants/common';
import { segmentTrackEventData } from 'src/utils/segment';
import { CRM_EVENT } from 'src/constants/crm-event';

interface SelectCardProps {
  onClose?: () => void;
  onSubmit?: (e: any) => void;
  loading?: boolean;
  apiError?: string;
}

const SelectCard = ({
  onClose = () => {},
  onSubmit = () => {},
  loading = false,
  apiError = ''
}: SelectCardProps) => {
  useEffect(() => {
    // SEND visit CRM event
    segmentTrackEventData(CRM_EVENT.VISIT, {
      [CRM_EVENT.CHOOSE_CARD_MODAL_KEY]: CRM_EVENT.CHOOSE_CARD_DESCRIPTION
    });
  }, []);

  return (
    <Modal onClose={onClose} className="max-w-5xl">
      {/* Slider Cards */}
      <div className="md:pb-28 pt-20 pb-5 md:flex md:flex-row md:justify-center md:items-center">
        <div className="max-w-lg">
          <SliderCard noInput={true} />
          <div className="mt-6 pl-4 hidden md:block">
            <CheckList data={CARD_BENEFITS} />
          </div>
        </div>
        <div className="max-w-lg md:ml-8 mt-8">
          <ReserveCard
            onSubmit={onSubmit}
            loading={loading}
            apiError={apiError}
          />
          <div className="mt-6 md:pl-4 md:hidden block">
            <CheckList data={CARD_BENEFITS} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectCard;
