import React, { useEffect, Suspense, useState, useRef } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { GetStaticProps } from 'next';
import { AxiosResponse } from 'axios';

// Layout
import { Layout } from '../layouts/Layout';

// Constants
import {
  CARD_INFO,
  SITE_DATA,
  CONTENT_WRAPPER,
  CHARITIES_INFO,
  ICONS_FEATURES,
  SEEN_IN_DATA
} from 'public/constants/common';
import {
  HOME_STYLES,
  FAQ_STYLES,
  CHARITIES_STYLES
} from 'public/constants/styles-constants';
import { HOME_CONTENTS } from 'public/constants/contents-constants';
import { APP_NAME, FEATURES_INFO } from 'src/constants/common';
import { ERROR_MESSAGES } from 'src/constants/error';
import { FEATURES_STYLES } from 'public/constants/styles-constants';
import { HOMEPAGE_MAIN_DATA } from 'public/constants/metadata';
import { ROUTES } from 'src/constants/routes';
import { CRM_EVENT } from 'src/constants/crm-event';

// Types
import { StaticPageProps } from '../types/Page';

// Components
import SeenInItem from 'src/components/SeenInItem';
import { StarsTwinkling } from 'src/components/StarsTwinkling';

// Sections
// import Titles from '../sections/Titles';
import CharitiesInfo from '../sections/Charities';
import FeaturesInfo from '../sections/FeaturesInfo';
import TopLoopVideo from '../sections/TopLoopVideo';
import GetCardIntro from '../sections/GetCardIntro';
import VerificationCodeModal from 'src/sections/VerificationCode';

// Hocs/Utils
import { segmentTrackEventData } from 'src/utils/segment';
import { checkInvalidUrl, getReferralCodeFromUrl } from 'src/utils/helper';
import {
  getReferralRewards,
  getFdicInsurance,
  joinWaitlistByEmail
} from 'src/utils/api';
import {
  getStorage,
  setStorage,
  removeStorage,
  STORAGE_KEYS
} from 'src/utils/storage';
import {
  useSelectCardModal,
  useVerificationCodeModal
} from 'src/hocs/useModal';
import {
  getOS,
  getAppLink,
  santizerPhone,
  formatCurrency
} from 'src/utils/helper';
import { sendSMS, signUpByEmail, signUpByEmailOrPhone } from 'src/utils/api';

// import styles from '../styles/landing.module.css';

interface BonusCoinsType {
  inviteeRewardAmount: number;
  rewardAmount: number;
}

// const SliderCard = React.lazy(() => import('../sections/SliderCard'));
const Index = ({ staticData }: StaticPageProps) => {
  const router = useRouter();
  const linkRef = useRef(null);
  const isAppPage = router.pathname === ROUTES.APP;

  const [data, setData] = useState({
    inviteeRewardAmount: getStorage(STORAGE_KEYS.INVITEE_REWARD_AMOUNT) || 20,
    rewardAmount: getStorage(STORAGE_KEYS.REWARD_AMOUNT) || 20,
    fdicInsurance:
      parseInt(getStorage(STORAGE_KEYS.FDIC_INSURANCE), 10) || 250000,
    loading: false,
    submittedSuccess: false,
    apiError: ''
  });

  const [inputFocus, setInputEmailFocus] = useState(false);
  const isPrideCard = SITE_DATA.prideCard;
  const isDogeCard = SITE_DATA.dogeCard;
  const isTerraCard = SITE_DATA.terraCard;
  const isAvalancheCard = SITE_DATA.avalancheCard;
  const isDogeCardSkeleton = SITE_DATA.dogeCardSkeleton;
  const isCauseCard = SITE_DATA.causeCard;

  const {
    toggleModal: toggleVerificationCodeModal,
    onOpen: onOpenVerificationCodeModal,
    onClose: onCloseVerificationModal,
    onSubmit: onSubmitVerification,
    apiError: apiErrorVerification,
    processing: processingVerification
  } = useVerificationCodeModal();

  const {
    onSubmit: onSubmitSelectCardModal,
    apiError: apiErrorSignIn,
    processing: processingSignIn
  } = useSelectCardModal();

  const fetchReferralRewards = async () => {
    if (!getStorage(STORAGE_KEYS.INVITEE_REWARD_AMOUNT)) {
      const response: AxiosResponse = await getReferralRewards();
      const responseData: BonusCoinsType = response.data;
      // Save bonus coin
      setStorage(
        STORAGE_KEYS.INVITEE_REWARD_AMOUNT,
        responseData.inviteeRewardAmount
      );
      // Save reward amount
      setStorage(STORAGE_KEYS.REWARD_AMOUNT, responseData.rewardAmount);
      setData({
        ...data,
        inviteeRewardAmount: responseData.inviteeRewardAmount,
        rewardAmount: responseData.rewardAmount
      });
    }
  };

  const fetchFdicInsurance = async () => {
    if (!getStorage(STORAGE_KEYS.FDIC_INSURANCE)) {
      const response: AxiosResponse = await getFdicInsurance();
      const responseData = response.data;
      const fdicInsurance = parseInt(responseData.fdicInsurance, 10);

      // Save FDIC insurance
      setStorage(STORAGE_KEYS.FDIC_INSURANCE, fdicInsurance);
      setData({
        ...data,
        fdicInsurance: fdicInsurance
      });
    }
  };

  useEffect(() => {
    const route = router.asPath;
    // Check if user enter the paths not in system
    // /: HOME
    // /legal
    // /next

    if (checkInvalidUrl(route)) {
      const referralResult = getReferralCodeFromUrl(
        router.asPath,
        referralCode => {
          router.push(`${ROUTES.HOME}${referralCode}`);
        }
      );

      if (!referralResult) {
        router.push(ROUTES.NOT_FOUND);
      } else {
        const { referralCode } = referralResult;
        if (!getStorage(STORAGE_KEYS.INVITER_REFERRAL_CODE) && referralCode) {
          setStorage(STORAGE_KEYS.INVITER_REFERRAL_CODE, referralCode);
        }
      }
    } else {
      const existedReferralCode = getStorage(
        STORAGE_KEYS.INVITER_REFERRAL_CODE
      );

      if (existedReferralCode) {
        removeStorage(STORAGE_KEYS.INVITER_REFERRAL_CODE);
      }
    }

    // SEND visit CRM event
    segmentTrackEventData(CRM_EVENT.VISIT, {
      [CRM_EVENT.HOME_KEY]: CRM_EVENT.HOME_DESCRIPTION
    });

    if (isPrideCard) {
      fetchReferralRewards();
    }

    if (isAvalancheCard) {
      fetchFdicInsurance();
    }

    if (isAppPage) {
      const handler = () => {
        linkRef.current.href = getAppLink(getOS());
        linkRef.current.click();
      };

      if (document.readyState === 'complete') {
        return handler();
      } else {
        window.addEventListener('load', handler);
        return () => document.removeEventListener('load', handler);
      }
    }

    return () => {};
  }, []);

  const onClickTopVideo = () => {
    window.scrollTo(0, 0);
    setInputEmailFocus(true);
  };

  const handleSendSMSSubmit = async (phone: string, type: string) => {
    setData({
      ...data,
      loading: true
    });

    const formatPhone = santizerPhone(`+1${phone}`);
    const referralCode = getStorage(STORAGE_KEYS.INVITER_REFERRAL_CODE) || '';

    try {
      const response: AxiosResponse = await sendSMS({
        phone: formatPhone,
        type,
        referralCode
      });

      if (response.status === 200) {
        setData({
          ...data,
          loading: false,
          submittedSuccess: true,
          apiError: ''
        });
      }
    } catch (error) {
      setData({
        ...data,
        loading: false,
        apiError:
          error.message.indexOf(400) > -1
            ? ERROR_MESSAGES.INVALID_PHONE
            : ERROR_MESSAGES.COMMON
      });
    }
  };

  const handleResetError = () => {
    setData({
      ...data,
      apiError: ''
    });
  };

  const handleSendEmailOrPhoneSubmit = async ({
    value,
    key,
    apiRequest,
    errorMessage
  }) => {
    setData({
      ...data,
      loading: true
    });

    try {
      const response: AxiosResponse = await apiRequest({
        [key]: value
      });

      if (response.status === 200) {
        setData({
          ...data,
          loading: false,
          submittedSuccess: true,
          apiError: ''
        });
      }
    } catch (error) {
      setData({
        ...data,
        loading: false,
        apiError:
          error.message.indexOf(400) > -1
            ? errorMessage
            : ERROR_MESSAGES.INVALID_REQUEST
      });
    }
  };

  const handleSubmitForm = (value, key) => {
    switch (key) {
      case APP_NAME.dogeCard:
        return handleSendSMSSubmit(value.account, 'downloadApp');
      case APP_NAME.pawsCard:
      case APP_NAME.terraCard:
        return handleSendEmailOrPhoneSubmit({
          value: value.account,
          key: 'email',
          apiRequest: APP_NAME.pawsCard ? joinWaitlistByEmail : signUpByEmail,
          errorMessage: ERROR_MESSAGES.INVALID_EMAIL
        });
      case APP_NAME.avalancheCard:
      case APP_NAME.choiceCard:
      case APP_NAME.liquidityCard:
      case APP_NAME.shibCard:
      case APP_NAME.causeCard:
        return handleSendEmailOrPhoneSubmit({
          value: value.account,
          key: 'email',
          apiRequest: signUpByEmailOrPhone,
          errorMessage: ERROR_MESSAGES.INVALID_EMAIL
        });
      default:
        return onSubmitSelectCardModal(value, onOpenVerificationCodeModal);
    }
  };

  useEffect(() => {
    if (data.submittedSuccess) {
      setTimeout(() => {
        setData({
          ...data,
          submittedSuccess: false
        });
      }, 4000);
    }
  }, [data.submittedSuccess]);

  return (
    <Layout>
      <Head>
        <title>{HOMEPAGE_MAIN_DATA.title}</title>
        <meta name="description" content={HOMEPAGE_MAIN_DATA.description} />
      </Head>
      <div
        className={`${
          !!process.env.NEXT_PUBLIC_APP_VIDEO_LINK_HOMEPAGE && isDogeCard
            ? 'pt-36 sm:pt-0'
            : ''
        } ${
          HOME_STYLES.contentWrapper
        } md:mx-auto w-full md:py-0 md:text-left text-center`}
      >
        {/* Video Section */}
        <Suspense fallback={<div />}>
          <TopLoopVideo
            videoLink={process.env.NEXT_PUBLIC_APP_VIDEO_LINK_HOMEPAGE}
            videoLinkMobile={
              process.env.NEXT_PUBLIC_APP_VIDEO_LINK_HOMEPAGE_MOBILE
            }
            apiError={data.apiError}
            loading={isPrideCard ? processingSignIn : data.loading}
            isShowedPopoverMessage={data.submittedSuccess}
            inputEmailFocus={inputFocus}
            onSubmit={value => handleSubmitForm(value, SITE_DATA.appName)}
            onResetError={handleResetError}
          />
        </Suspense>
        <div
          className={`${staticData?.classNameWrapperContent} md:mx-auto w-full md:text-left text-center relative`}
        >
          {isDogeCard && <StarsTwinkling startNumber={42} />}
          {/* Banner Section */}
          {/* <div className="relative md:flex-row flex flex-col flex-col-reverse items-center md:items-start">
            <StarsTwinkling startNumber={20} />
            <div
              className={`md:ml-20 md:w-1/3 md:ml-4
              transform -rotate-6 bg-center md:bg-left sm:h-144
              my-20 md:mt-20 w-full flex items-center justify-start
              bg-no-repeat bg-contain ${styles['bg-frame-sell']}`}
            ></div>
            <div className="md:w-2/3 w-full mb-12 md:pl-10 pl-0">
              {staticData?.titleBanner && (
                <Titles
                  className={
                    'text-center md:mb-9 mb-12 md:text-left md:text-3xl md:pl-8 leading-7 md:leading-10 text-2xl font-bold font-primary mx-auto md:mx-0'
                  }
                  title={staticData?.titleBanner?.title}
                  salutionTitle={staticData?.titleBanner?.salutionTitle}
                  subTitle={staticData?.titleBanner?.subTitle}
                  dogeTitle={staticData?.titleBanner?.dogeTitle}
                  kingTitle={staticData?.titleBanner?.kingTitle}
                />
              )}
              {/* Slider Cards */}
          {/* <Suspense fallback={<div />}>
                <SliderCard />
              </Suspense>
            </div> */}
          {/*</div> */}
          {/* Charities Section */}
          {isPrideCard && (
            <div className={`${CHARITIES_STYLES.bgLayout}`}>
              <h4 className="font-tertiary text-white text-3xl text-center font-semibold text-lg mb-8 pt-12 xl:text-5xl xl:leading-2lg">
                Give Back with Every Swipe
              </h4>
              <p className="font-tertiary xl:text-3xl xl:max-w-screen-2xl text-xl text-white text-center px-20 mx-auto pb-12 xl:leading-lg">
                {HOME_CONTENTS.charitiesTitle}
              </p>
              <div
                className={`rounded-lg flex mx-auto justify-items-center grid gap-4 grid-cols-1 mx-7 relative p-4 md:container sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5`}
              >
                {CHARITIES_INFO.map(item => (
                  <CharitiesInfo
                    {...item}
                    key={item.iconName}
                    className={'font-tertiary text-white leading-ls-md'}
                  />
                ))}
              </div>
            </div>
          )}
          {/* Seen in Section */}
          {isDogeCard && staticData?.seenInData?.length > 0 && (
            <div className="flex flex-col lg:flex-row lg:justify-between w-full lg:py-8 2xl:py-12 mb-20 sm:mb-24 xl:mb-28 2xl:mb-36 items-center">
              <StarsTwinkling startNumber={20} />
              <h4 className="w-full lg:w-1/3 mb-8 sm:mb-12 lg:mb-0 text-center lg:text-left text-white font-semibold text-xl leading-8 sm:text-2xl sm:leading-10 xl:text-4xl xl:leading-lg">
                As seen in
              </h4>
              <div className="w-full lg:w-2/3 flex justify-between 2xl:pl-6 2xl:max-w-6xl">
                {staticData?.seenInData.map(item => (
                  <SeenInItem
                    {...item}
                    key={item.iconName}
                    className="text-white max-w-seen-xs sm:max-w-seen xl:max-w-seen-xl 2xl:max-w-seen-2xl"
                  />
                ))}
              </div>
            </div>
          )}
          {/* Features Section */}
          {isDogeCard && (
            <h4
              className={`text-center mb-12 sm:mb-20 xl:text-4xl font-semibold ${FEATURES_STYLES.title}`}
            >
              {`Your ${SITE_DATA.appName} is secured by`}
            </h4>
          )}
          {staticData?.featuresInfo.length > 0 && !isAvalancheCard && (
            <div className={`${staticData?.classNameWrapperFeatures}`}>
              <div
                className={`rounded-lg flex mx-auto justify-items-center grid gap-4 grid-cols-1 mx-7 relative md:grid-cols-3 ${FEATURES_STYLES.contentWrapper}`}
              >
                {isDogeCard && <StarsTwinkling startNumber={20} />}
                {staticData?.featuresInfo.map(item => (
                  <FeaturesInfo
                    iconPath={item.iconPath}
                    iconName={item.iconName}
                    iconWidth={item.iconWidth}
                    iconHeight={item.iconHeight}
                    info={item.info}
                    iconLinkTo={item.iconLinkTo}
                    key={item.key}
                    className={`${FEATURES_STYLES.contentText}`}
                  />
                ))}
              </div>
            </div>
          )}

          {/* <div
            className={`md:block hidden bg-no-repeat ${styles['bg-what-you-get']}`}
          ></div> */}

          {/* Get Cards Info Section */}
          {staticData?.getCardIntro?.length > 0 && (
            <div className="relative z-20">
              {staticData?.getCardIntro.map((item, index) => {
                const key = `item-${index}`;

                if (item?.imageInfo) {
                  return (
                    <GetCardIntro
                      className={`${item?.className} ${
                        (index % 2 !== 0 && isDogeCardSkeleton) ||
                        (index % 2 === 0 && !isDogeCardSkeleton)
                          ? 'md:flex-row'
                          : 'md:flex-row-reverse'
                      }`}
                      bgClassName={item?.bgClassName}
                      onClickTopVideo={onClickTopVideo}
                      claimCardInfo={{
                        labelButton: item?.claimCardInfo?.labelButton,
                        className: item?.claimCardInfo?.className,
                        titleClassName: item?.claimCardInfo?.titleClassName,
                        titleHeader: item?.claimCardInfo?.titleHeader.replaceAll(
                          '$rewardAmount',
                          data.rewardAmount.toString()
                        ),
                        titleInfo: {
                          title: item?.claimCardInfo?.titleInfo?.title.replaceAll(
                            '$rewardAmount',
                            data.rewardAmount.toString()
                          ),
                          subTitle: item?.claimCardInfo?.titleInfo?.subTitle,
                          dogeTitle: item?.claimCardInfo?.titleInfo?.dogeTitle
                        }
                      }}
                      imageInfo={{
                        // alt: item?.imageInfo?.alt,
                        // src: item?.imageInfo?.src,
                        width: item?.imageInfo?.width,
                        className: item?.imageInfo?.className,
                        wrapperClassName: item?.imageInfo?.wrapperClassName,
                        imageClassName: item?.imageInfo?.imageClassName,
                        height: item?.imageInfo?.height,
                        linkTo: item?.imageInfo?.linkTo,
                        value: item?.imageInfo?.value
                      }}
                      icons={item?.icons}
                      key={key}
                      content={(() => {
                        if (!index) {
                          return `<ul class="${
                            HOME_STYLES.listItemText
                          } md:text-2xl text-base text-left sm:text-xl pb-12">
                          ${`
                             <li class="get-transfer">Donate to animal charity of your choice</li>
                             <li class="get-money">Cash-back</li>
                          `}
                          ${isDogeCard ? HOME_CONTENTS.listCrypto : ''}
                        </ul>`;
                        }
                        if (isPrideCard && index === 3) {
                          return `<ol class="${
                            HOME_STYLES.listItemText
                          } md:text-2xl text-base text-left sm:text-xl pb-12 -mt-12">
                          ${
                            !isDogeCardSkeleton
                              ? `<li class="get-transfer">FDIC insured account, through <a href='https://www.getevolved.com' target='_blank'>Evolve Bank and Trust</a></li>
                                <li class="get-money">${HOME_CONTENTS.protection} fraud and liability protection</li>
                                `
                              : ''
                          }
                          ${isDogeCard ? HOME_CONTENTS.listCrypto : ''}
                        </ol>`;
                        }

                        switch (index) {
                          case 0:
                            return `<ol class="${
                              HOME_STYLES.listItemText
                            } md:text-2xl hidden pt-6 list-complete text-base text-left sm:text-xl pb-12 -mt-12">
                                      <li class="get-transfer">Donate to LGBTQIA+ charity of your choice</li>
                                      <li class="get-money">Cash-back</li>
                                      ${
                                        !isPrideCard
                                          ? HOME_CONTENTS.listCrypto
                                          : ''
                                      }
                                    </ol>`;
                          case 1:
                            return `<ol class="${
                              HOME_STYLES.listItemText
                            } md:text-2xl text-base text-left sm:text-xl pb-12 -mt-12">
                                      ${
                                        !isDogeCardSkeleton
                                          ? `<li class="get-transfer">FDIC insured account, through <a href='https://www.getevolved.com' target='_blank'>Evolve Bank and Trust</a></li>
                                            <li class="get-money">${HOME_CONTENTS.protection} fraud and liability protection</li>
                                            `
                                          : ''
                                      }
                                    </ol>`;
                          case 2:
                            return `<ol class="${
                              HOME_STYLES.listItemText
                            } md:text-2xl text-base text-center md:text-left sm:text-xl pb-12 -mt-12 ${
                              isAvalancheCard ? '' : 'hidden'
                            }">
                                      <li class="get-transfer">Your cash is FDIC insured by <a href='https://www.getevolved.com' target='_blank' class="text-highlight">Evolve Bank and Trust up to $${formatCurrency(
                                        data.fdicInsurance
                                      )}</a></li>
                                      ${
                                        isAvalancheCard
                                          ? ''
                                          : `<li class="get-money">Your purchases are protected by ${HOME_CONTENTS.protection} fraud and liability protection</li>`
                                      }
                                      ${HOME_CONTENTS.zeroHash}
                                    </ol>`;
                          case 4:
                            return `<ol class="${
                              HOME_STYLES.listItemText
                            } md:text-2xl text-base text-left text-xl pb-12 -mt-12" ${
                              isDogeCard ? '' : 'hidden'
                            }>
                                    <li class="get-transfer leading-4md xl:leading-lg">Your cash is FDIC insured by <a href='https://www.getevolved.com' target='_blank' class="text-highlight">Evolve Bank and Trust up to $${formatCurrency(
                                      data.fdicInsurance
                                    )}</a></li>
                                    <li class="get-money leading-4md xl:leading-lg">Your purchases are protected by ${
                                      HOME_CONTENTS.protection
                                    } fraud and liability protection</li>
                                    ${HOME_CONTENTS.zeroHash}
                                  </ol>`;
                          // return `<ol class="text-white xs:text-base text-xl md:text-2xl pb-12 -mt-12">
                          //           <li class="get-transfer mb-4">Add money to your DogeCard</li>
                          //           <li class="get-money mb-4 md:mx-0 mx-10">Spend online or in person</li>
                          //           <li class="get-coin mb-4">Earn Dogecoin on every purchase</li>
                          //         </ol>`;
                          // return `<ol class="text-white xs:text-base text-xl md:text-2xl pb-12 -mt-12">
                          //           <li class="get-transfer mb-4">Add money to your DogeCard</li>
                          //           <li class="get-money mb-4 md:mx-0 mx-10">Spend online or in-person, anywhere Visa is accepted</li>
                          //           <li class="get-coin mb-4">Earn Dogecoin on every purchase</li>
                          //         </ol>`;
                          default:
                            return '';
                        }
                      })()}
                    />
                  );
                } else {
                  return (
                    <div
                      className={`relative w-full flex flex-col items-center ${
                        !isPrideCard ? 'pb-10 pt-20 md:pt-32' : 'z-20'
                      } justify-end`}
                    >
                      <ul className="md:flex justify-center md:flex-row w-full">
                        {item.imageList.map(imageItem => (
                          <li className="w-full">
                            <img
                              className="w-full"
                              alt={imageItem.alt}
                              src={imageItem.src}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                }
              })}
            </div>
          )}
          {/* {isDogeCard && (
            <div
              id="faq"
              className={`relative pt-20 pb-0 ${FAQ_STYLES.contentLayout}`}
            >
              {isDogeCard && (
                <>
                  <StarsTwinkling startNumber={60} />
                  <StarsTwinkling startNumber={30} />
                </>
              )}
              <div className={process.env.NEXT_PUBLIC_APP_ELFSIGHT_KEY}></div>
            </div>
          )} */}
        </div>
        {toggleVerificationCodeModal && (
          <VerificationCodeModal
            loading={processingVerification}
            apiError={apiErrorVerification}
            onClose={onCloseVerificationModal}
            onSubmit={onSubmitVerification}
          />
        )}
      </div>
      {isAppPage && (
        <a href="#" ref={linkRef}>
          {' '}
        </a>
      )}
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      staticData: {
        titleHead: 'Marketing Website',
        titleBanner: {
          subTitle: 'Get Dogecoin back on every purchase'
        },
        classNameWrapperContent: CONTENT_WRAPPER.classNameWrapperContent,
        classNameWrapperFeatures: CONTENT_WRAPPER.classNameWrapperFeatures,
        seenInData: SEEN_IN_DATA,
        featuresInfo: FEATURES_INFO,
        getCardIntro: CARD_INFO
        // getCardIntro: [
        //   {
        //     className: CARD_INFO.classNameFrameComplete,
        //     claimCardInfo: {
        //       labelButton: CARD_INFO.claimCardInfoComplete.buttonLabel,
        //       titleHeader: CARD_INFO.claimCardInfoComplete.mainTitle,
        //       className: 'font-secondary',
        //       titleInfo: {
        //         title: CARD_INFO.claimCardInfoComplete.information
        //       }
        //     },
        //     imageInfo: {
        //       // alt: CARD_INFO.imageInfoComplete.alt,
        //       // src: CARD_INFO.imageInfoComplete.src,
        //       imageClassName: CARD_INFO.imageInfoComplete.imageClassName,
        //       className: CARD_INFO.imageInfoComplete.className,
        //       width: CARD_INFO.imageInfoComplete.width,
        //       height: CARD_INFO.imageInfoComplete.height
        //     }
        //   },
        //   {
        //     className: CARD_INFO.classNameFrameMember,
        //     claimCardInfo: {
        //       labelButton: CARD_INFO.claimCardInfoFrameMember.buttonLabel,
        //       titleHeader: CARD_INFO.claimCardInfoFrameMember.mainTitle,
        //       titleInfo: {
        //         title: CARD_INFO.claimCardInfoFrameMember.information
        //       }
        //     },
        //     imageInfo: {
        //       // alt: CARD_INFO.imageInfoFrameMember.alt,
        //       // src: CARD_INFO.imageInfoFrameMember.src,
        //       imageClassName: CARD_INFO.imageInfoFrameMember.imageClassName,
        //       className: CARD_INFO.imageInfoFrameMember.className,
        //       height: CARD_INFO.imageInfoFrameMember.height,
        //       linkTo: CARD_INFO.imageInfoFrameMember.linkTo
        //     }
        //   },
        //   /** HIDE How it works SECTION INSTEAD Safe and Secure - CHANGES 20211111 */
        //   // {
        //   //   claimCardInfo: {
        //   //     labelButton: 'Join Waitlist',
        //   //     titleHeader: 'How it works',
        //   //     titleInfo: {
        //   //       title: ''
        //   //     }
        //   //   },
        //   //   imageInfo: {
        //   //     alt: 'Frame Phone',
        //   //     src: IMAGES_LANDING.framePhone,
        //   //     className: 'bg-frame-phone',
        //   //     width: 737,
        //   //     height: 737
        //   //   }
        //   // },
        //   /** HIDE section - changes 20220305*/
        //   // {
        //   //   className: CARD_INFO.classNameEvolveBank,
        //   //   claimCardInfo: {
        //   //     labelButton: CARD_INFO.claimCardInfoEvolveBank.buttonLabel,
        //   //     titleHeader: CARD_INFO.claimCardInfoEvolveBank.mainTitle,
        //   //     className: 'font-secondary',
        //   //     titleInfo: {
        //   //       title: CARD_INFO.claimCardInfoEvolveBank.information
        //   //     }
        //   //   },
        //   //   imageInfo: {
        //   //     alt: CARD_INFO.imageInfoEvolveBank.alt,
        //   //     src: CARD_INFO.imageInfoEvolveBank.src,
        //   //     className: CARD_INFO.imageInfoEvolveBank.className,
        //   //     width: CARD_INFO.imageInfoEvolveBank.width,
        //   //     height: CARD_INFO.imageInfoEvolveBank.height,
        //   //     value: CARD_INFO.imageInfoEvolveBank.value
        //   //   }
        //   // },
        //   {
        //     className: CARD_INFO.classNameFramePhone,
        //     claimCardInfo: {
        //       classNameWrapper: CARD_INFO.claimCardInfoFramePhone?.classNameWrapper,
        //       labelButton: CARD_INFO.claimCardInfoFramePhone?.buttonLabel,
        //       titleHeader: CARD_INFO.claimCardInfoFramePhone?.mainTitle,
        //       titleInfo: {
        //         title: CARD_INFO.claimCardInfoFramePhone?.information
        //       }
        //     },
        //     imageInfo: {
        //       // alt: CARD_INFO.imageInfoFramePhone.alt,
        //       // src: CARD_INFO.imageInfoFramePhone.src,
        //       imageClassName: CARD_INFO.imageInfoFramePhone?.imageClassName,
        //       className: CARD_INFO.imageInfoFramePhone?.className,
        //       width: CARD_INFO.imageInfoFramePhone?.width,
        //       height: CARD_INFO.imageInfoFramePhone?.height
        //     }
        //   },
        //   {
        //     className: CARD_INFO.classNameStayControl,
        //     claimCardInfo: {
        //       labelButton: CARD_INFO.claimCardInfoStayControl.buttonLabel,
        //       titleHeader: CARD_INFO.claimCardInfoStayControl.mainTitle,
        //       titleInfo: {
        //         title: CARD_INFO.claimCardInfoStayControl.information
        //       }
        //     },
        //     imageInfo: {
        //       // alt: CARD_INFO.imageInfoStayControl.alt,
        //       // src: CARD_INFO.imageInfoStayControl.src,
        //       imageClassName: CARD_INFO.imageInfoStayControl.imageClassName,
        //       className: CARD_INFO.imageInfoStayControl.className,
        //       width: CARD_INFO.imageInfoStayControl.width,
        //       height: CARD_INFO.imageInfoStayControl.height
        //     }
        //   },
        //   //** HIDE section - changes 20220310*/
        //   // {
        //   //   className: CARD_INFO.classNameFees,
        //   //   claimCardInfo: {
        //   //     labelButton: CARD_INFO.claimCardInfoFees.buttonLabel,
        //   //     titleHeader: CARD_INFO.claimCardInfoFees.mainTitle,
        //   //     titleInfo: {
        //   //       title: CARD_INFO.claimCardInfoFees.information
        //   //     }
        //   //   },
        //   //   imageInfo: {
        //   //     alt: CARD_INFO.imageInfoFees.alt,
        //   //     className: CARD_INFO.imageInfoFees.className,
        //   //     src: CARD_INFO.imageInfoFees.src,
        //   //     width: CARD_INFO.imageInfoFees.width,
        //   //     height: CARD_INFO.imageInfoFees.height
        //   //   }
        //   // },
        //   {
        //     className: CARD_INFO.classNameReferFriends,
        //     claimCardInfo: {
        //       labelButton: CARD_INFO.claimCardInfoReferFriends.buttonLabel,
        //       titleHeader: CARD_INFO.claimCardInfoReferFriends.mainTitle,
        //       titleInfo: {
        //         title: CARD_INFO.claimCardInfoReferFriends.information
        //       }
        //     },
        //     imageInfo: {
        //       // alt: CARD_INFO.imageInfoReferFriends.alt,
        //       // src: CARD_INFO.imageInfoReferFriends.src,
        //       imageClassName: CARD_INFO.imageInfoReferFriends.imageClassName,
        //       className: CARD_INFO.imageInfoReferFriends.className,
        //       width: CARD_INFO.imageInfoReferFriends.width,
        //       height: CARD_INFO.imageInfoReferFriends.height
        //     }
        //   }
        //   // {
        //   //   className: CARD_INFO.classNameFees,
        //   //   claimCardInfo: {
        //   //     labelButton: CARD_INFO.claimCardInfoFees.buttonLabel,
        //   //     titleHeader: CARD_INFO.claimCardInfoFees.mainTitle,
        //   //     titleInfo: {
        //   //       title: CARD_INFO.claimCardInfoFees.information
        //   //     }
        //   //   },
        //   //   imageInfo: {
        //   //     // alt: CARD_INFO.imageInfoFees.alt,
        //   //     // src: CARD_INFO.imageInfoFees.src,
        //   //     className: CARD_INFO.imageInfoFees.className,
        //   //     imageClassName: CARD_INFO.imageInfoFees.imageClassName,
        //   //     width: CARD_INFO.imageInfoFees.width,
        //   //     height: CARD_INFO.imageInfoFees.height
        //   //   }
        //   // }
        // ]
      }
    }
  };
};

export default Index;
