import React from 'react';

interface PopoverMessageProps {
  messageTitle?: string;
  messageSub?: string;
}

const PopoverMessage = ({
  messageTitle = '',
  messageSub = ''
}: PopoverMessageProps) => {
  return (
    <div
      className="bg-gray-300 rounded-lg p-3 shadow-md relative items-center"
      role="alert"
    >
      <p className="block text-left text-xs font-bold pb-1">{messageTitle}</p>
      <span className="block text-left text-xs">{messageSub}</span>
    </div>
  );
};

export default PopoverMessage;
