import React, { useState, useEffect, useRef, LegacyRef } from 'react';
import Titles from 'src/sections/Titles';
import { InputText } from 'src/components/InputText';
import PopoverMessage from 'src/components/PopoverMessage';
import { Button } from 'src/components/Button';

import DownloadButtonsGroup from 'src/components/DownloadButtonsGroup';
import { Text } from 'src/components/Content/index';
import { CONNECTION } from 'src/constants/common';
import {
  SITE_DATA,
  BUTTON_DOWNLOAD,
  POPUP_ALERT
} from 'public/constants/common';
import { isBrowser } from 'src/utils/helper';
import { TOP_VIDEO_STYLES } from 'public/constants/styles-constants';

import useApp from 'src/hocs/useApp';
import { getStorage, STORAGE_KEYS } from 'src/utils/storage';
import { validation, formatPhoneNumber } from 'src/utils/helper';

interface VideoProps {
  videoLink?: string;
  videoLinkMobile?: string;
  apiError?: string;
  loading?: boolean;
  inputEmailFocus?: boolean;
  isShowedPopoverMessage?: boolean;
  onSubmit?: (e: any) => void;
  onResetError?: () => void;
}

const TopLoopVideo = ({
  videoLink = '',
  videoLinkMobile = '',
  apiError = '',
  loading = false,
  inputEmailFocus = false,
  isShowedPopoverMessage = false,
  onSubmit = () => {},
  onResetError = () => {}
}: VideoProps) => {
  const { onSaveMultiData } = useApp();
  const videoRef = useRef(null);

  const [inputData, setInputData] = useState({
    inputChanged: false,
    value: getStorage(STORAGE_KEYS.PHONE) || '',
    error: ''
  });

  const isDogeCard = SITE_DATA.dogeCard;
  const isAvalancheCard = SITE_DATA.avalancheCard;
  const isTerraCard = SITE_DATA.terraCard;
  const isPrideCard = SITE_DATA.prideCard;
  const isCauseCard = SITE_DATA.causeCard;
  const isPawsCard = SITE_DATA.pawsCard;
  const connectionInput = isDogeCard ? CONNECTION.SMS : CONNECTION.EMAIL;
  const storageKeyInput = isDogeCard ? STORAGE_KEYS.PHONE : STORAGE_KEYS.EMAIL;
  const isShowSubTitle = !(isPrideCard || isTerraCard || isAvalancheCard);

  const validateAccount = value => {
    const key = isDogeCard ? 'phone' : 'email';
    const errorMsg = validation({ value, key });

    return {
      key,
      errorMsg
    };
  };

  const onBlurInput = (event, isKeyEnter) => {
    const value = event.target.value;
    const { errorMsg } = validateAccount(value);
    setInputData({
      ...inputData,
      error: (inputEmailFocus || inputData.inputChanged) && errorMsg
    });
    onSaveMultiData(
      [event.target.value, event.target.value, connectionInput],
      [storageKeyInput, STORAGE_KEYS.ACCOUNT, STORAGE_KEYS.CONNECTION]
    );
    if (isKeyEnter) {
      return !errorMsg;
    } else {
      return true;
    }
  };

  const onSubmitVerificationInput = isValid => {
    const newAccount = getStorage(storageKeyInput);
    const newConnection = getStorage(STORAGE_KEYS.CONNECTION);
    if ((!inputData.error && !!newAccount) || isValid) {
      onSubmit({
        account: newAccount,
        connection: newConnection
      });
    } else {
      setInputData({
        ...inputData,
        error: validateAccount(newAccount).errorMsg
      });
    }
  };

  const onChangeInput = event => {
    const value = event.target.value;
    const formattedValueInput = isDogeCard ? formatPhoneNumber(value) : value;

    setInputData({
      ...inputData,
      value: formattedValueInput,
      inputChanged: true,
      error: ''
    });
    onResetError();
  };

  const onKeyEnter = event => {
    if (event.keyCode === 13) {
      if (onBlurInput(event, true)) {
        onSubmitVerificationInput(true);
      }
    }
  };

  useEffect(() => {
    if (isBrowser) {
      document.body.addEventListener(
        'touchstart',
        () => {
          videoRef.current?.play();
        },
        { once: true }
      );
    }
  }, []);

  return (
    <div
      className={`${!!videoLink && isDogeCard ? 'flex flex-col' : ''} ${
        TOP_VIDEO_STYLES.bannerWrapper
      } w-full relative`}
    >
      <div className={`order-last ${TOP_VIDEO_STYLES.classBgVideo}`}>
        {!!videoLink && videoLink !== `"` ? (
          <>
            <video
              autoPlay
              loop
              muted
              ref={videoRef as LegacyRef<HTMLVideoElement>}
              className={`${
                !!videoLink ? '' : `video-bg`
              } hidden w-full h-auto sm:h-auto md:block sm:block bg-no-repeat object-cover relative`}
              poster="/video/bg-video.png"
            >
              {!!videoLink && <source src={videoLink} type="video/mp4" />}
            </video>
            <video
              autoPlay
              loop
              muted
              playsInline
              className={`hidden w-full xs:block bg-no-repeat object-cover ${
                !!videoLinkMobile
                  ? 'h-auto'
                  : `${isPrideCard ? 'h-auto' : 'h-144'}`
              }`}
              poster="/video/bg-video-mobile.png"
              ref={videoRef}
            >
              {!!videoLinkMobile && (
                <source src={videoLinkMobile} type="video/mp4" />
              )}
            </video>
          </>
        ) : (
          <div
            className={`banner-bg-landing w-full h-auto contain bg-no-repeat bg-contain object-cover relative ${TOP_VIDEO_STYLES.bannerBgLayout}`}
          />
        )}
      </div>
      {(isCauseCard || isPawsCard) && (
        <>
          <div
            className={`absolute z-10 bg-cover bg-center bg-no-repeat star-icon star-banner`}
          />
          <div
            className={`absolute z-10 bg-cover bg-center bg-no-repeat star-icon curved-arrow curved-arrow-banner`}
          />
          <div
            className={`absolute z-10 bg-cover bg-center bg-no-repeat star-icon twisted-arrow twisted-arrow-banner`}
          />
        </>
      )}
      <div
        className={`${
          !!videoLink && isDogeCard
            ? 'xl:absolute xl:-translate-y-1/2 xl:transform order-first mb-0 sm:mt-40 xl:mt-10 mt-8 sm:mt-20 mb-11 sm:mb-14 md:mb-32'
            : 'absolute mb-20 transform -translate-y-1/2'
        } ${
          TOP_VIDEO_STYLES.titlePosition
        } z-20 xl:mb-0 sm:px-12 px-4 w-full lg:max-w-5xl xl:max-w-6xl`}
      >
        <Titles
          title={SITE_DATA.bannerTitle}
          className={`uppercase mx-auto sm:ml-0 text-3xs max-w-xs text-left bold-text ${TOP_VIDEO_STYLES.textTitle}`}
        />
        {isShowSubTitle && SITE_DATA.subBannerTitle && (
          <span
            className={`${TOP_VIDEO_STYLES.textSubTitle} block text-white pt-2 xs:text-center text-left font-primary font-normal`}
          >
            {SITE_DATA.subBannerTitle}
          </span>
        )}
        <div
          className={`w-full flex flex-col justify-start relative ${TOP_VIDEO_STYLES.formWrapper}`}
        >
          <div className="flex xs:flex-col w-full">
            <div
              className={`${TOP_VIDEO_STYLES.emailInputWrapper} flex flex-col text-left`}
            >
              <InputText
                error={inputData.error || apiError}
                defaultValue={getStorage(STORAGE_KEYS.PHONE)}
                value={inputData.value}
                onBlur={event => onBlurInput(event, false)}
                onChange={event => onChangeInput(event)}
                placeholder={SITE_DATA.inputPlaceholderText}
                autoFocus={true}
                type={isDogeCard ? 'tel' : 'text'}
                maxLength={isDogeCard ? 14 : 50}
                onKeyDown={onKeyEnter}
                size="fullWidth"
                className={[
                  `mt-4 mb-2 outline-none h-14 text-black bg-white xs:max-w-full ${TOP_VIDEO_STYLES.emailInput}`
                ].join('')}
                key={inputEmailFocus.toString()}
              />
            </div>
            {isShowedPopoverMessage && (
              <div className="my-4 w-72 md:w-80 xs:w-80 absolute top-16 left-0 z-10">
                <PopoverMessage
                  messageTitle={POPUP_ALERT.messageTitle}
                  messageSub={POPUP_ALERT.messageSub}
                />
              </div>
            )}
            <div className={`${TOP_VIDEO_STYLES.buttonWrapper} w-full`}>
              <Button
                label={isDogeCard ? 'Sign Up' : 'Join Waitlist'}
                size="fullWidth"
                onClick={() => onSubmitVerificationInput(false)}
                disabled={loading}
                className={[
                  `mt-4 xs:mt-2 xs:mr-0 ${isPrideCard ? 'font-tertiary' : ''}`
                ].join('')}
                bgClassName={isTerraCard ? 'bg-secondary' : ''}
              />
            </div>
          </div>
          {!isTerraCard && (
            <div
              className={[
                `inline-block flex-wrap leading-tight text-xss sm:max-w-md sm:flex text-left`
              ].join('')}
            >
              <Text
                wrapperClassName={`${TOP_VIDEO_STYLES.textTermWrapper}`}
                className={`mr-1 ${TOP_VIDEO_STYLES.textTerm}`}
                primaryText={`By tapping ${
                  isDogeCard ? '‘Sign Up’' : '‘Join Waitlist’'
                }, you agree to our`}
                secondaryText="Terms,"
                primaryClassName={TOP_VIDEO_STYLES.textTermColor}
                size={TOP_VIDEO_STYLES.textTermSize}
                consent="E-sign Consent"
                privacy="Privacy Policy."
              />
            </div>
          )}
          {isDogeCard && (
            <div className="flex sm:w-full sm:justify-center lg:justify-start">
              <DownloadButtonsGroup
                className="sm:justify-center md:justify-start mt-6 lg:mt-8"
                btnInfosDefault={BUTTON_DOWNLOAD}
                btnClass="bg-gray-900 sm:mx-2 md:ml-0 xl:bg-black lg:mr-4"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopLoopVideo;
