import React from 'react';
import Link from 'next/link';

// Types
import { CharitiesInfoPropsType } from '../../types/Page';

// Components
import LazyLoadImage from 'src/components/LazyLoadImage';

// Constant
import { CHARITIES_STYLES } from 'public/constants/styles-constants';

const CharitiesInfo = ({
  iconPath,
  iconName = '',
  info = '',
  iconLinkTo = '',
  iconWidth = 0,
  iconHeight = 0,
  className = ''
}: CharitiesInfoPropsType) => {
  return (
    <div
      className={`${CHARITIES_STYLES.contentLayout} flex flex-col items-center mx-auto max-w-sm`}
    >
      <div
        className={`${CHARITIES_STYLES.iconLayout} flex items-center justify-center order-2`}
      >
        {!!iconLinkTo ? (
          <Link href={iconLinkTo}>
            <a target="_blank">
              <LazyLoadImage
                alt={iconName}
                src={iconPath}
                width={iconWidth}
                height={iconHeight}
              />
            </a>
          </Link>
        ) : (
          <LazyLoadImage
            alt={iconName}
            src={iconPath}
            width={iconWidth}
            height={iconHeight}
          />
        )}
      </div>
      <span
        className={`text-lg xs:text-base text-center px-4 leading-text-xsm max-w-xs md:order-2 xs:order-2 ${className}`}
      >
        {info}
      </span>
    </div>
  );
};

export default CharitiesInfo;
