import React, { useState } from 'react';
import Link from 'next/link';
import dynamic from 'next/dynamic';

// Const
import { ROUTES } from '../../constants/routes';
import {
  HEADER_STYLES,
  BUTTONS_STYLES,
  VERIFICATION_STYLES
} from 'public/constants/styles-constants';

// Sections
import Modal from '../Modal';
import { CountDownTimer } from './CountDownTimer';

// Components
import { Button } from '../../components/Button';
import LazyLoadImage from 'src/components/LazyLoadImage';

// Utils
import { getStorage, STORAGE_KEYS } from 'src/utils/storage';
import { useSelectCardModal } from 'src/hocs/useModal';
import { InputText } from 'src/components/InputText';
import { validation } from 'src/utils/helper';

// const ReactPinField = dynamic(() => import('react-pin-field'), {
//   ssr: false
// });

interface VerificationCodeProps {
  onClose?: () => void;
  onSubmit?: (code: string) => void;
  loading?: boolean;
  apiError?: string;
}

const VerificationCodeModal = ({
  onClose = () => {},
  onSubmit = () => {},
  loading = false,
  apiError = ''
}: VerificationCodeProps) => {
  const [data, setData] = useState({
    code: '',
    internalError: ''
  });
  const [startTimeCount, setStartTimeCount] = useState(false);

  const {
    onResend,
    apiError: apiErrorSignIn,
    processing: processingSignIn
  } = useSelectCardModal();

  // const { selectedCard } = useApp();

  const onComplete = (value: string) => {
    // Validate here
    setData({
      internalError: validation({ value, key: 'code' }),
      code: value
    });
  };

  // Change state startTimeCount to false
  const onCountEnd = () => {
    setStartTimeCount(false);
  };

  // Click btn Resend Code call countTimeDown
  const resendCode = () => {
    onResend(
      {
        // name: getStorage(STORAGE_KEYS.USER_NAME),
        account: getStorage(STORAGE_KEYS.ACCOUNT),
        connection: getStorage(STORAGE_KEYS.CONNECTION)
        // cardType: selectedCard
      },
      () => {
        setStartTimeCount(true);
      }
    );
  };

  return (
    <Modal onClose={onClose} className="max-w-3xl">
      <header className="flex items-center justify-center">
        <Link href={ROUTES.HOME}>
          <a className="flex items-center text-xl xs:flex-col xs:text-2xl">
            <LazyLoadImage
              alt="logo"
              src={'/logos/logo-dialog.png'}
              width={80}
              height={80}
              className={HEADER_STYLES.logoLayout}
            />
          </a>
        </Link>
      </header>
      <div className="flex flex-col justify-center items-center verification-code">
        <h2 className="uppercase text-white text-info-black mb-1 mt-4 font-bold text-lg">
          Enter The Verification Code
        </h2>
        <span className="text-white text-info-black mb-8 text-sm">
          Code was send to {getStorage(STORAGE_KEYS.ACCOUNT)}
        </span>
        {/* <div className="max-w-lg xs:hidden">
          <ReactPinField
            autoComplete="on"
            autoFocus
            length={6}
            validate={/^[0-9]$/}
            className="text-center text-white m-1 md:m-2 rounded-lg w-8 h-9 md:w-14 md:h-16 bg-bg-btn-close"
            type="password"
            onComplete={onComplete}
          />
        </div> */}
        <InputText
          pattern="[0-9]*"
          type="number"
          inputtype="numeric"
          autocomplete="one-time-code"
          id="pincode"
          error={data.internalError}
          defaultValue=""
          onBlur={(event: any) => {
            onComplete(event.target.value);
          }}
          placeholder="Enter code"
          size="fullWidth"
          className={`${VERIFICATION_STYLES.inputTextPinCode} outline-none bg-input-form`}
        />
        {(apiError || apiErrorSignIn) && (
          <span className="block font-medium tracking-wide text-red-500 text-xs my-5 text-center">
            {apiError || apiErrorSignIn}
          </span>
        )}
        {startTimeCount && <CountDownTimer onCountEnd={onCountEnd} />}
        <Button
          className={`sm:mt-5 mt-1 xs:mb-1 text-highlight ${
            BUTTONS_STYLES.textColorBtnResendCode
          } ${BUTTONS_STYLES.bgButtonResendCode} ${
            startTimeCount || processingSignIn
              ? 'cursor-not-allowed opacity-50'
              : ''
          }`}
          label="Resend Code"
          disabled={startTimeCount || processingSignIn}
          onClick={resendCode}
        />
        <Button
          disabled={loading || processingSignIn}
          primary
          className="btn-main-lg sm:mt-4 mt-0"
          label="Continue"
          onClick={() => {
            if (!data.internalError) {
              onSubmit(data.code);
            }
          }}
        />
      </div>
    </Modal>
  );
};

export default VerificationCodeModal;
