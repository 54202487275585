import React from 'react';
import styles from './title.module.css';

interface TitleProps {
  primary: string;
  secondary?: string;
  description?: string;
  className?: string;
  size?: 'tiny' | 'small' | 'medium' | 'large';
  weight?: 'normal' | 'bold';
}

const getTitleSize = size => {
  switch (size) {
    case 'tiny':
      return 'text-xl';
    case 'small':
      return 'text-3xl';
    case 'large':
      return 'text-4xl';
    default:
      return 'text-2md';
  }
};

export const ListTitle = ({
  primary,
  secondary,
  description,
  size,
  className = '',
  weight,
  ...props
}: TitleProps) => (
  <p
    className={[getTitleSize(size), 'xs:mb-5 xs:py-0 py-4 text-left'].join(' ')}
    {...props}
  >
    <span
      className={[
        styles[`title-font--${weight}`],
        `uppercase sm:normal-case ${className}`
      ].join(' ')}
    >
      {primary}
    </span>
    <span
      className={[
        styles['sub-title'],
        styles[`title-font--${weight}`],
        'text-yellow text-highlight'
      ].join(' ')}
    >
      {secondary}
    </span>

    <span className={[styles[`title-font--${weight}`], 'text-white'].join(' ')}>
      {description}
    </span>
  </p>
);
