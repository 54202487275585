import React from 'react';
import styles from './popover.module.css';
import LazyLoadImage from '../../components/LazyLoadImage';

interface PopoverProps {
  numberText: string;
  bonusText: string;
  size?: 'small' | 'medium';
  width?: 'min' | 'auto' | 'max';
  mode?: string;
  className?: string;
}

const getWidthSize = width => {
  switch (width) {
    case 'min':
      return 'w-44';
    case 'auto':
      return 'w-48';
    default:
      return 'w-52';
  }
};

const Popover = ({
  numberText,
  bonusText,
  size,
  width,
  className = '',
  mode = 'gold',
  ...props
}: PopoverProps) => (
  <div
    className={[
      styles['popover-wrapper'],
      mode === 'gold' ? styles['popover-wrapper--gold'] : '',
      getWidthSize(width),
      'flex justify-center items-center text-white w-64 xs:w-56 xs:h-16 h-20 relative z-20',
      className
    ].join(' ')}
  >
    <div
      className={[
        styles['pop-over'],
        mode === 'gold' ? styles['popover--gold'] : '',
        getWidthSize(width),
        'flex justify-center items-center text-white relative'
      ].join(' ')}
    >
      <div
        className={[
          styles['pop-over-content'],
          'max-w-sm rounded xs:text-base',
          `${size === 'small' ? 'text-sm' : 'text-lg'}`
        ].join(' ')}
      >
        <div
          className={['flex font-primary mb-6 xs:mb-2 xs:ml-1'].join('')}
          {...props}
        >
          <span className={['pr-2.5 xs:mt-1 text-sm xs:text-xs'].join('')}>
            {numberText}
          </span>
          <LazyLoadImage
            className={[
              styles['coin-img'],
              'object-cover',
              'full-rounded'
            ].join(' ')}
            src={'/logos/logo-coin.png'}
            alt="logo-popover"
            width={22}
            height={22}
          />
          <span className={['pl-1.5 xs:mt-1 text-sm xs:text-xs'].join('')}>
            {bonusText}
          </span>
        </div>
      </div>
    </div>
    <div className={styles['popover-arrow']}></div>
  </div>
);

export default Popover;
