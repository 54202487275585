import React from 'react';

// Types
import { GetCardIntroPropsType } from '../types/Page';

// Sections
import { ClaimCardInfo } from '../sections/ClaimCardInfo';

// Constant
import { SITE_DATA } from 'public/constants/common';
import { GET_CARD_INFO_STYLES } from 'public/constants/styles-constants';

// Components
import { StarsTwinkling } from 'src/components/StarsTwinkling';

// Styles
import styles from '../styles/landing.module.css';

const GetCardIntro = ({
  className = '',
  bgClassName = '',
  claimCardInfo = {
    className: '',
    titleClassName: '',
    labelButton: '',
    titleHeader: '',
    titleInfo: {
      title: '',
      subTitle: '',
      dogeTitle: ''
    }
  },
  imageInfo = {
    // alt: '',
    // src: '',
    wrapperClassName: '',
    className: '',
    width: 0,
    height: 0,
    linkTo: '',
    value: '',
    imageClassName: ''
  },
  icons = [],
  content = '',
  onClickTopVideo,
  ...props
}: GetCardIntroPropsType) => {
  const imageLink = imageInfo?.linkTo;
  const imageName = imageInfo?.value;
  const imageClass = imageInfo?.imageClassName;
  const wrapperClassName = imageInfo?.wrapperClassName;
  const classInfo = claimCardInfo?.className;
  const hasTitleHeader = !!claimCardInfo?.titleHeader;
  const isDogeCard = SITE_DATA.dogeCard;
  const isPrideCard = SITE_DATA.prideCard;
  const isCauseCard = SITE_DATA.causeCard;
  const isPawsCard = SITE_DATA.pawsCard;

  return (
    <div
      className={`${GET_CARD_INFO_STYLES.contentLayout}
        ${bgClassName} `}
      {...props}
    >
      <div
        className={`relative w-full flex flex-col items-center ${
          imageLink && !isPrideCard
            ? 'pt-20 md:pt-32 3xl:max-w-screen-3xl 3xl:mx-auto'
            : ''
        } ${imageName ? 'mb-24 md:mt-28' : ''} justify-end ${className}`}
      >
        {isDogeCard && <StarsTwinkling startNumber={20} />}
        {(isCauseCard || isPawsCard) &&
          icons &&
          icons.map(item => (
            <div
              className={`absolute z-10 bg-cover bg-center bg-no-repeat ${item}`}
            />
          ))}
        {imageLink && hasTitleHeader ? (
          <div className="flex items-center justify-center w-full z-20">
            <a
              target="_blank"
              href={imageLink}
              aria-label="This is Member Fidc"
              className={`${GET_CARD_INFO_STYLES.imageLinkLayout} ${imageClass} w-full flex items-center justify-center`}
            >
              <span
                className={`bg-no-repeat bg-contain bg-center h-full ${wrapperClassName} ${
                  imageInfo.className
                } ${styles[`${imageInfo.className}`]}`}
              />
            </a>
          </div>
        ) : (
          hasTitleHeader && (
            <div
              className={`flex flex-col ${
                imageName
                  ? `${GET_CARD_INFO_STYLES.imageInfoLayout} h-80 py-20 sm:mb-20 sm:mt-24`
                  : `${imageClass}
                    `
              } w-full justify-start items-center`}
            >
              <div
                className={`h-full flex items-center justify-start bg-no-repeat bg-center ${wrapperClassName} ${!isPrideCard &&
                  'bg-contain'} ${imageInfo?.className} ${
                  styles[`${imageInfo?.className}`]
                }`}
              />
              {imageName && (
                <span
                  className={`${GET_CARD_INFO_STYLES.imageInfo}
                  } block w-1/2 xs:w-full text-center text-white text-info mt-3`}
                >
                  {imageInfo?.value}
                </span>
              )}
            </div>
          )
        )}
        {hasTitleHeader && (
          <div className={`w-full ${classInfo}`}>
            <div
              className={`${GET_CARD_INFO_STYLES.cardInfoLayout} md:max-w-3xl`}
            >
              <ClaimCardInfo
                labelButton={claimCardInfo?.labelButton}
                titleHeader={claimCardInfo?.titleHeader}
                className={claimCardInfo?.titleClassName}
                titleInfo={claimCardInfo?.titleInfo}
                content={content}
                onClickTopVideo={onClickTopVideo}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GetCardIntro;
