import React from 'react';

// Types
import { SeenInType } from '../../types/Page';

// Components
import LazyLoadImage from 'src/components/LazyLoadImage';

const SeenInItem = ({
  className = '',
  iconPath,
  iconName = '',
  iconWidth = 0,
  iconHeight = 0,
  info = ''
}: SeenInType) => {
  return (
    <div className={`seen-in flex flex-row items-center ${className}`}>
      <div className="flex items-center justify-center">
        <LazyLoadImage
          alt={iconName}
          src={iconPath}
          width={iconWidth}
          height={iconHeight}
        />
      </div>
      {info && (
        <span className="text-xs text-left sm:text-xl sm:leading-6 2xl:text-3xl 2xl:leading-9 max-w-seen-info xl:max-w-seen-info-lg 2xl:max-w-seen-info-xl pl-1.5 sm:pl-2 xl:pl-4">
          {info}
        </span>
      )}
    </div>
  );
};

export default SeenInItem;
