import React, { useRef, useEffect } from 'react';
import Slider from 'react-slick';

// Constants
import { CARDS } from 'src/constants/common';

// Section
import Card from 'src/sections/Card';
import SelectCard from 'src/sections/SelectCard';
import VerificationCodeModal from 'src/sections/VerificationCode';

// Component
import { Button } from 'src/components/Button';
// import { InputText } from 'src/components/InputText';

// Styles
import styles from './SliderCard.module.css';
import { STORAGE_KEYS } from 'src/utils/storage';

// Hocs/Utils
import {
  useSelectCardModal,
  useVerificationCodeModal
} from 'src/hocs/useModal';
import ReserveCard from 'src/components/ReserveCard';
import useApp from 'src/hocs/useApp';
import Popover from 'src/components/Popover';

interface SelectCardProps {
  noInput?: boolean;
}

const SliderCard = ({ noInput = false }: SelectCardProps) => {
  const slider = useRef(null);
  const { selectedCard, userName, onSaveData } = useApp();

  const settings = {
    className: 'cards-slider z-10 mx-auto sm:mx-0',
    dots: true,
    infinite: true,
    speed: 500,
    initialSlide: CARDS.find(item => item.value === selectedCard)?.id,
    beforeChange: (_, next) => {
      onSaveData(CARDS[next].value, STORAGE_KEYS.SELECTED_CARD);
    }
  };

  const {
    toggleModal: toggleSelectCardModal,
    onOpen: onOpenSelectCardModal,
    onClose: onCloseSelectCardModal,
    onSubmit: onSubmitSelectCardModal,
    apiError: apiErrorSignIn,
    processing: processingSignIn,
    setApiError: resetErrorSignIn
  } = useSelectCardModal();

  const {
    toggleModal: toggleVerificationCodeModal,
    onOpen: onOpenVerificationCodeModal,
    onClose: onCloseVerificationModal,
    onSubmit: onSubmitVerification,
    apiError: apiErrorVerification,
    processing: processingVerification,
    setApiError: resetErrorVerification
  } = useVerificationCodeModal();

  const handleTypeWriter = () => {
    var TxtType = function(el, toRotate, period) {
      this.toRotate = toRotate;
      this.el = el;
      this.loopNum = 0;
      this.period = parseInt(period, 8) || 7000;
      this.txt = '';
      this.tick();
      this.isDeleting = false;
    };

    TxtType.prototype.tick = function() {
      var i = this.loopNum % this.toRotate.length;
      var fullTxt = this.toRotate[i];

      if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
      } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
      }

      this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

      var that = this;
      var delta = 150 - Math.random() * 100;

      if (this.isDeleting) {
        delta /= 2;
      }

      if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        if (this.loopNum > 1) {
          return;
        }
        this.isDeleting = true;
      } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
      }

      setTimeout(function() {
        that.tick();
      }, delta);
    };

    var elements = document.getElementsByClassName('typewrite');
    for (var i = 0; i < elements.length; i++) {
      var toRotate = elements[i].getAttribute('data-type');
      var period = elements[i].getAttribute('data-period');
      if (toRotate) {
        /*eslint-disable no-new */
        new TxtType(elements[i], JSON.parse(toRotate), period);
      }
    }
    // INJECT CSS
    var css = document.createElement('style');
    css.type = 'text/css';
    css.innerHTML = '.typewrite > .wrap { border-right: 0.08em solid }';
    document.body.appendChild(css);
  };

  useEffect(() => {
    resetErrorSignIn('');
    resetErrorVerification('');
    handleTypeWriter();
  }, []);

  return (
    <div className="flex flex-col xs:justify-center relative">
      <Popover
        numberText="+5"
        bonusText="signup bonus for you!"
        className=""
        mode={selectedCard}
      />
      <Slider ref={slider} {...settings}>
        {CARDS.map(item => (
          <div
            className="flex sm:text-right xs:text-center outline-none relative sm:pb-10 md:pl-8 md:pt-3.5"
            key={item.id}
          >
            <div
              className={`card h-auto md:w-10/12 rounded-3xl outline-none mx-auto md:mx-0 relative ${
                selectedCard === CARDS[0].value ? styles['card-shadow'] : ''
              }`}
            >
              {noInput ? (
                <label
                  className={`absolute text-xl z-10 bottom-3/20 left-5
                    ${styles['userName']} ${
                    selectedCard === CARDS[1].value
                      ? 'text-black'
                      : 'text-white'
                  }`}
                >
                  {userName}
                </label>
              ) : (
                <section
                  className={`absolute hidden max-w-xs  md:ml-5 mb-2 xs:hidden ${
                    noInput ? 'hidden' : 'sm:flex'
                  } ${
                    selectedCard === CARDS[1].value
                      ? 'border-black text-black'
                      : 'border-white text-white'
                  } form-input border outline-none md:p-4 pl-7 z-10
                  px-3 py-4 bottom-3/25 left-5 input-username`}
                >
                  <span
                    className="typewrite"
                    data-period="2000"
                    data-type='["Enter your name", "Enter your name", "Enter your name"]'
                  >
                    <span
                      className={`wrap ${
                        selectedCard === CARDS[1].value
                          ? 'border-black'
                          : 'border-white'
                      }`}
                    ></span>
                  </span>
                </section>
                // <InputText
                //   defaultValue={userName}
                //   key={userName}
                //   onBlur={(event: any) => {
                //     onSaveData(event.target.value, STORAGE_KEYS.USER_NAME);
                //   }}
                //   placeholder="Enter your name"
                //   className={`absolute hidden ${noInput ? 'hidden' : 'sm:block'
                //     } form-input bg-bg-input-slider
                // border border-gray-400 outline-none md:p-6 pl-7 z-10 px-3 py-4
                // bottom-3/20 rounded-lg left-10`}
                // />
              )}
              <Card
                alt={item.label}
                src={item.url}
                width={item.width}
                height={item.height}
                className="rounded-xl cursor-pointer"
                onClick={noInput ? () => {} : onOpenSelectCardModal}
              />
            </div>
          </div>
        ))}
      </Slider>
      <div
        className={`md:w-10/12 md:pl-8 flex w-full mt-8 sm:mt-0 justify-center ${
          noInput ? 'justify-center' : 'sm:justify-between'
        } items-center`}
      >
        <div
          className={`z-20 sm:bottom-0 hidden ${
            noInput ? 'hidden' : 'sm:block'
          } text-white rounded-lg  left-1/2 sm:left-0`}
        >
          <Button primary label={'Sign Up'} onClick={onOpenSelectCardModal} />
        </div>
        <div className="md:w-52 lg:w-60 w-56 flex justify-between items-center">
          <div
            className={`block z-10 w-8 h-8 cursor-pointer rounded-lg bg-no-repeat bg-center bg-bg-button outline-none ${styles['prev']}`}
            onClick={() => slider?.current?.slickPrev()}
          />
          <span className="text-highlight xs:leading-5 inline-block sm:px-3 sm:pb-1 text-center w-2/3 truncate">
            {'Change Color'}
          </span>
          <div
            className={`block w-8 h-8 cursor-pointer rounded-lg bg-no-repeat bg-center bg-bg-button outline-none ${styles['next']}`}
            onClick={() => slider?.current?.slickNext()}
          />
        </div>

        {toggleSelectCardModal && (
          <SelectCard
            apiError={apiErrorSignIn}
            loading={processingSignIn}
            onClose={onCloseSelectCardModal}
            onSubmit={data =>
              onSubmitSelectCardModal(data, onOpenVerificationCodeModal)
            }
          />
        )}
        {toggleVerificationCodeModal && (
          <VerificationCodeModal
            loading={processingVerification}
            apiError={apiErrorVerification}
            onClose={onCloseVerificationModal}
            onSubmit={onSubmitVerification}
          />
        )}
      </div>
      <div
        className={`xs:mt-20 ${
          noInput ? 'hidden' : 'sm:block'
        } text-white rounded-lg`}
      >
        <ReserveCard
          className={['hidden md:hidden xs:block xs:mt-8'].join(' ')}
          apiError={apiErrorSignIn}
          loading={processingSignIn}
          onSubmit={data =>
            onSubmitSelectCardModal(data, onOpenVerificationCodeModal)
          }
        />
      </div>
    </div>
  );
};

export default SliderCard;
